const validationTypes = {
  not_empty: (value, translatable, languages) => {
    return value && value !== "-1"
  },
  not_blank: (value, translatable, languages) => {
    const validation = v => v && v.length > 0 && value !== "-1"
    if (translatable) {
      let valid = true
      Object.keys(languages).forEach(language => {
        if (!validation(value[language])) {
          valid = false
        }
      })
      return valid
    }
    return validation(value)
  },
  password: (value, translatable, languages, extraData) => {
    if (extraData.mode === 'edit' && value === '') return true
    return value && value === extraData.repeated
  },
  password_min_length: (value, translatable, languages, extraData) => {
    if (extraData.mode === 'edit' && value === '') return true
    return value && value.length > 5
  },
  min_length: (value, translatable, languages, extraData) => {
    return value && value.length > 5
  },
}

const Errors = (value, validations, translatable, languages, extraData = {}) => {
  const valid = []
  if (validations) {
    Object.keys(validations).forEach(validation => {
      if (
        typeof validationTypes[validation] !== 'undefined'
        && !validationTypes[validation](value, translatable, languages, extraData)
      ) {
        valid.push(validations[validation])
      }
    })
  }
  return valid
}

export default Errors
