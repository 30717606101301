import React, { useState, useEffect } from 'react'

import LanguageSelector from './LanguageSelector'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'

const Number = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable,
  options: { label, decimals = 0 },
  validations,
  languages,
  parentIsSorting,
  innerForm,
}) => {
  const [language, setLanguage] = useState(
    translatable ? Object.keys(languages)[0] : null,
  )
  const [value, setValue] = useState(
    defaultValue(name, storedData, translatable),
  )
  const [errors, setErrors] = useState([])
  const [currentValue, setCurrentValue] = useState('')

  const onChangeText = e => {
    let newValue = e.target.value || ''
    if (newValue && decimals > 0) {
      newValue = parseFloat(newValue)
    } else if (newValue) {
      newValue = parseInt(newValue, 10)
    }
    if (translatable) {
      value[language] = newValue
      setValue({ ...value })
    } else {
      setValue(newValue)
    }
    setCurrentValue(newValue)
  }

  useEffect(() => {
    setCurrentValue(translatable ? `${value[language] || ''}` : `${value}`)
  }, [language, translatable])

  useEffect(() => {
    // if (defaultValue(name, storedData, translatable) === value) return
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value])

  const input = (
    <input
      name={name}
      className={`w-full rounded px-2 py-1 ${
        showErrors && errors.length > 0 ? 'border border-error' : ''
      } ${
        translatable && Object.keys(languages)[0] === language
          ? 'rounded-tl-none'
          : ''
      } ${innerForm ? 'bg-white' : 'bg-grayLight'} outline-none placeholder-gray`}
      type="number"
      step={`${decimals ? `0.${decimals}` : '1'}`}
      placeholder={label}
      value={currentValue}
      onChange={onChangeText}
    />
  )

  if (parentIsSorting && name === 'position') {
    return null
  }

  return (
    <div>
      <label>{label}</label>
      {translatable ? (
        <LanguageSelector
          language={language}
          languages={languages}
          onChangeLanguage={v => setLanguage(v)}
        >
          {input}
        </LanguageSelector>
      ) : (
        input
      )}
    </div>
  )
}

export default Number
