import React, { useState, useEffect, useRef } from 'react'
import LanguageSelector from './LanguageSelector'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'
import { JsonEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

const Json = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable,
  options: { label, markdown },
  validations,
  languages,
  innerForm,
  models,
}) => {
  const [language, setLanguage] = useState(
    translatable ? Object.keys(languages)[0] : null,
  )
  const [value, setValue] = useState(
    defaultValue(name, storedData, translatable),
  )
  const [errors, setErrors] = useState([])
  const inputJson = useRef(null);
  const [currentValue, setCurrentValue] = useState(`
    {
      "config": {
        "sort": {
          "position": "asc"
        },
        "list": {
          "position": "Position",
          "title": "Name"
        },
        "title": "ModelName"
      },
      "fields": [
        {
          "name": "position",
          "type": "number",
          "options": {
            "label": "Position"
          }
        },
        {
          "name": "title",
          "type": "text",
          "translatable": true,
          "options": {
            "label": "Title"
          }
        }
      ]
    }
  `)

  const jsonEditor = useRef(null)

  const onChangeText = v => {
    if (translatable) {
      value[language] = v
      setValue({ ...value })
    } else {
      setValue(v)
    }
    setCurrentValue(v)
  }

  useEffect(() => {
    try {
      if (currentValue && JSON.stringify(jsonEditor.current.jsonEditor.get()) !== currentValue) {
        jsonEditor.current.jsonEditor.set(JSON.parse(currentValue))
        jsonEditor.current.jsonEditor.expandAll()
      }
    } catch (e) {
      console.log('Error', e)
    }
  }, [currentValue])

  useEffect(() => {
    setCurrentValue(translatable ? `${value[language] || ''}` : `${value}`)
  }, [value, language, translatable])

  useEffect(() => {
    if (defaultValue(name, storedData, translatable) === value) return
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value])

  const textarea = (
    <div>
      <JsonEditor
        ref={jsonEditor}
        value={currentValue ? JSON.parse(currentValue) : ''}
        onChange={e => {
          onChangeText(JSON.stringify(e))
        }}
        name={name}
        className={`w-full h-32 px-2 py-1 ${
          showErrors && errors.length > 0 ? 'border border-error' : ''
        } rounded ${
          translatable && Object.keys(languages)[0] === language
            ? 'rounded-tl-none'
            : ''
        } ${innerForm ? 'bg-white' : 'bg-grayLight'} outline-none resize-y`}
        placeholder={label}
        onError={e => {
          console.log('Error', e)
        }}
      />
    </div>
  )

  const [notificationText, setNotificationText] = useState('')
  const [showJsonTextArea, setShowJsonTextArea] = useState(false)


  useEffect(() => {
    if (notificationText) {
      setTimeout(() => {
        setNotificationText('')
      }, 3000);
    }
  }, [notificationText])

  const copyJSON = (text) => {
    navigator.clipboard.writeText(JSON.stringify(jsonInput, undefined,2))
    setNotificationText(`Copied to clipboard`)
  }

  const insertJSON = () => {
    setShowJsonTextArea(false)
    if (jsonInput) {
      setNotificationText(`Inserted JSON`)
      onChangeText(JSON.stringify(jsonInput))
    }
  }

  const [jsonInput, setJsonInput] = useState(currentValue)

  useEffect(() => {
    if (currentValue) {
      setJsonInput(JSON.parse(currentValue))
    }
    if (inputJson.current) {
      inputJson.current.focus()
      inputJson.current.select()
    }
  }, [currentValue,showJsonTextArea])  

  const handleViewJSON = () => {
    setShowJsonTextArea(true)
  }

  return (
    <div>
      {showJsonTextArea && <div className="fixed inset-0 z-40 flex items-center justify-center " style={{ backgroundColor: 'rgba(255,255,255,.8)' }}>
        <div className='absolute inset-0 ' onClick={() => setShowJsonTextArea(false)} />
        <div className='w-1/2 bg-white border rounded border-gray'>
          <div className='relative flex flex-col items-end p-4 pt-2'>
            <div className='w-full'>
              <span className="w-full cursor-pointer hover:text-primary hover:wght-semibold" onClick={copyJSON}>Copy JSON</span>
              <span className='ml-2 text-xs cursor-default hover:text-black hover:wght-normal'>{notificationText}</span>
            </div>
            <textarea name="" id="" cols="30" rows="20" className='w-full p-2 mb-2 font-mono text-xs border border-black rounded' value={JSON.stringify(jsonInput, undefined,2)} onChange={(e) => setJsonInput(JSON.parse(e.target.value))} ref={inputJson}></textarea>
            <div className='flex justify-end'>
              <span
                onClick={() => setShowJsonTextArea(false)}
                className="px-2 py-0.5 mx-1 text-red border border-red rounded cursor-pointer  bg-white hover:bg-grayLighter"
              >
                Cancel
              </span>
              <span
                onClick={insertJSON}
                className="px-2 py-0.5 mx-1 text-white rounded cursor-pointer  bg-primary hover:bg-black"
              >
                Insert
              </span>
            </div>
          </div>
        </div>
        </div>
      }
      <div className="flex items-center justify-between">
        <label>{label}</label>
        <div className='flex'>
          <span className="h-full cursor-pointer hover:text-primary hover:wght-semibold" onClick={handleViewJSON}>JSON</span>
        </div>
      </div>
      {translatable ? (
        <LanguageSelector
          language={language}
          languages={languages}
          onChangeLanguage={v => setLanguage(v)}
        >
          {textarea}
        </LanguageSelector>
      ) : (
        textarea
      )}
    </div>
  )
}

export default Json
