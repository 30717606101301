import React, { useState, useEffect } from 'react'
import LanguageSelector from './LanguageSelector'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'
import throttle from 'lodash/throttle'
import Cookies from 'js-cookie'
import countries from '../Lib/Countries'

const sanitizeValue = v => v
  .split(',')
  .filter(i => i.length > 0 && i !== ',')
  .join(',')

const Select = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable,
  options: {
    type, label, model, multiple, options: typeOptions, where, restrict_by_creationUserId, search,
  },
  validations,
  languages,
  find,
  innerForm,
}) => {
  const [language, setLanguage] = useState(
    translatable ? Object.keys(languages)[0] : null,
  )
  const [options, setOptions] = useState([{ _id: -1, title: label }])
  const [value, setValue] = useState(
    defaultValue(name, storedData, translatable, multiple ? '' : -1),
  )
  const [searchValue, setSearchValue] = useState(multiple ? 'Search' : (value.title || value.name))
  const [originalOptions, setOriginalOptions] = useState(false)
  const [searchOptions, setSearchOptions] = useState()
  const [errors, setErrors] = useState([])
  const [currentValue, setCurrentValue] = useState(multiple ? '' : -1)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let rawOriginalOptions = false
    if (type === 'country') {
      rawOriginalOptions = countries['en'].map(country => ({
        '_id': country.alpha2,
        'title': country.name
      }))
    } else if (search && type === 'static') {
      rawOriginalOptions = Object.entries(typeOptions).map(option => ({
        '_id': option[0],
        'title': option[1]
      }))
    }
    setOriginalOptions(rawOriginalOptions)
  }, [])
  

  const shouldAddMultipleValue = (val, v) => {
    const rawVal = val || []
    if (!rawVal.includes(v)) {
      rawVal.push(v)
    }
    return [...rawVal]
  }

  const onChangeText = e => {
    if (translatable) {
      value[language] = multiple
        ? shouldAddMultipleValue(value[language], e.target.value)
        : e.target.value
      setValue({ ...value })
    } else {
      setValue(
        multiple
          ? shouldAddMultipleValue(value, e.target.value)
          : e.target.value,
      )
    }
  }

  const onChangeSearch = e => {
    const searchValue = e.target.value
    setSearchValue(searchValue)

    if (type === 'external' && searchValue !== '') {
      setLoading(true)
      externalSearchThrottled({
        value: searchValue,
        query: typeOptions.query,
        variables: typeOptions.variables,
        setSearchOptions,
        setLoading
      })
    } else if (searchValue !== '') {
      const newOptions = originalOptions.filter(option => {
        const optionLowercase = option.title.toLowerCase()
        const searchLowercase = searchValue.toLowerCase()
        return optionLowercase.indexOf(searchLowercase) !== -1
      })
      setSearchOptions(newOptions)
    }
  }

  const removeItem = id => {
    if (translatable) {
      value[language] = value[language].filter(item => item !== id)
      setValue({ ...value })
    } else {
      setValue([...value.filter(item => item !== id)])
    }
  }

  useEffect(() => {
    setCurrentValue(translatable ? `${value[language] || -1}` : `${value}`)
  }, [value, language, translatable])

  useEffect(() => {
    if (type === 'static') {
      setOptions(o => [
        ...o,
        ...Object.keys(typeOptions).map(key => ({
          id: key,
          title: typeOptions[key],
        })),
      ])
    } else {
      setOptions([{ _id: -1, title: 'Loading...' }])
      if (typeof find[model] !== 'undefined') {
        setOptions([{ _id: -1, title: label }, ...find[model]])
      }
    }
  }, [name, type, typeOptions])

  useEffect(() => {
    // if (defaultValue(name, storedData, translatable) === value) return
    const errs = Errors(value._id || value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
    setSearchValue(multiple ? 'Search' : (value.title || value.name))
  }, [value])

  const multipleOption = id => {
    const idKey = search ? 'id' : '_id'
    let option = options.find(item => item[idKey] === id)
    return (
      <div
        key={id}
        className="flex-grow-0 flex-shrink-0 px-1 mb-1 mr-1 text-xs border border-black rounded"
      >
        {option ? option.title : `Not found ${id}`}
        <span className="pl-1 cursor-pointer" onClick={() => removeItem(id)}>
          ✕
        </span>
      </div>
    )
  }
  const select = (
    <div>
      {!search ?
        <div className="relative">
          <div className="absolute right-0 py-1 mr-2">&#8595;</div>
          <select
            name={name}
            className={`w-full px-2 py-1 ${innerForm ? 'bg-white' : 'bg-grayLight'} ${
              showErrors && errors.length > 0 ? 'border border-error' : ''
            } rounded ${
              translatable && Object.keys(languages)[0] === language
                ? 'rounded-tl-none'
                : ''
            } outline-none appearance-none`}
            type="text"
            placeholder={label}
            value={multiple ? -1 : currentValue}
            onChange={onChangeText}
          >
            {options.map(option => (
              <option
              key={option._id + option.title}
              disabled={
                option.disabled
                || (multiple && currentValue.includes(option._id))
              }
              value={option._id}
              >
                {option.title}
              </option>
            ))}
          </select>
        </div>
      :
        <div className="relative">
          <input
            name={name}
            className={`w-full px-2 py-1 ${innerForm ? 'bg-white' : 'bg-grayLight'} ${
              showErrors && errors.length > 0 ? 'border border-error' : ''
            } rounded ${
              translatable && Object.keys(languages)[0] === language
                ? 'rounded-tl-none'
                : ''
            } outline-none appearance-none`}
            type="text"
            placeholder='Search'
            value={(searchValue && searchValue !== -1) ? searchValue : ''}
            onFocus={ () => {
              setSearchValue('')
              setSearchOptions(originalOptions)
            }}
            onBlur={ () => {
              setSearchValue(multiple ? 'Search' : (value.title || value.name))
              setTimeout(() => {
                setSearchOptions()
              }, 200);
            } }
            onChange={onChangeSearch}
          />
          {(searchOptions || loading) &&
            <div className={`box-border absolute z-50 w-full py-1 -mt-1 overflow-y-scroll ${innerForm ? 'bg-white' : 'bg-grayLight'} rounded-sm rounded-b top-8 border-1 `} style={{ maxHeight: '14rem' }}>
              {(searchOptions.length > 0 && searchOptions !== 'no-options') ?
                searchOptions.map(option => (
                  <div
                    className="box-border block w-full rounded cursor-pointer hover:bg-purple"
                    onClick={() => onChangeText({target: {value: option}})}
                    key={option._id}
                  >
                    <span className="p-2">{option.title || option.name}</span>
                  </div>
                ))
              :
                <div className="box-border block w-full rounded">
                  {loading ?
                    <span className="p-2">loading...</span>
                  :
                    <span className="p-2">No result</span>
                  }
                </div>
              }
            </div>
          }
        </div>
      }

      {multiple && currentValue.length > 0 && options.length > 1 && (
        <div className="flex flex-wrap mt-2">
          {currentValue
            .split(',')
            .map(id => id.length > 0 && multipleOption(id))}
        </div>
      )}
    </div>
  )

  return (
    <div>
      <label>{label}</label>
      {translatable ? (
        <LanguageSelector
          language={language}
          languages={languages}
          onChangeLanguage={v => setLanguage(v)}
        >
          {select}
        </LanguageSelector>
      ) : (
        select
      )}
    </div>
  )
}

const externalSearchThrottled = throttle((params) => externalSearch(params), 800, { leading: false, trailing: true })

const externalSearch = (params) => {
  const {value, query, variables, setSearchOptions, setLoading} = params
  const bodyData = { query, value, variables }

  fetch(`${process.env.REACT_APP_API_URL}external-search`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Cookies.get('token')}`,
    },
    body: JSON.stringify(bodyData),
  }).then(res => res.json())
  .then(res => {
    setLoading(false)
    if (res && !res.errors) {
      const options = Object.values(res).map(item => {
        item._id = item.id
        delete item.id
        return item
      })
      setSearchOptions(options)
    } else {
      setSearchOptions('no-options')
    }
  })
}


export default Select
