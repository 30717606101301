import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';

import Form from './Form/Form';
import flatten from './Lib/flatten';

const modelsWithoutList = [];

const Edit = () => {
  const history = useHistory();
  const { name: modelName, id: urlId, groupName } = useParams();
  const { state } = useLocation();
  const initialPosition = state ? state.index : false;
  const [id, setId] = useState(urlId);
  const [data, setData] = useState(false);
  const [name, setName] = useState(modelName);
  const [title, setTitle] = useState(name);
  const [tabTitle, setTabTitle] = useState(name);
  const [showErrors, setShowErrors] = useState(false);
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);

  const updateStored = (key, value, errs = []) => {
    if (data.stored && typeof data.stored.modelName === 'undefined') {
      data.stored.modelName = name;
    }
    data.stored[key] = value;
    errors[key] = errs;
    setData({ ...data });
    setErrors({ ...errors });
  };

  useEffect(() => {
    setData(false);
    setName(modelName);

    const bodyData = {
      modelName,
    };

    if (id) {
      bodyData._id = id;
    }

    fetch(`${process.env.REACT_APP_API_URL}model-edit`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        if (response.status === 200) {
          setTitle(
            `${id ? 'Edit' : 'Create'} ${response.body.modelConfig.title}`
          );
          setTabTitle(
            `${id ? 'Edit' : 'Create'} ${response.body.modelConfig.title} - ${
              response.body.config.app.title
            }`
          );
          const rawData = response.body;
          if (
            (typeof rawData.stored.position === 'undefined' ||
              rawData.stored.position.length === 0) &&
            initialPosition &&
            initialPosition !== ''
          ) {
            rawData.stored.position = initialPosition;
          }
          setData(rawData);
        } else {
          if (response.status === 403) {
            Cookies.remove('token');
            history.push('/login');
          }
        }
      });
  }, [modelName, id]);

  const orderModels = function (data) {
    Object.keys(data).forEach((key) => {
      const staticElement = data[key];
      if (Array.isArray(staticElement)) {
        staticElement.forEach((jsonElement, key2) => {
          if (
            jsonElement['items'] &&
            jsonElement['items'] !== -1 &&
            Array.isArray(jsonElement['items'])
          ) {
            data[key][key2]['items'] = jsonElement['items'].sort(
              (a, b) => a.position - b.position
            );
          }
        });
      }
    });
  };

  const save = (type = 'default', callback = false) => {
    const errs = flatten(errors);
    if (Object.keys(flatten(errs)).length > 0) {
      setShowErrors(true);
    } else {
      setShowErrors(false);
      setSaving(true);
      orderModels(data.stored);
      fetch(`${process.env.REACT_APP_API_URL}model-update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
        body: JSON.stringify({ ...data.stored, modelName }),
      })
        .then((response) =>
          response
            .json()
            .then((data) => ({ status: response.status, body: data }))
        )
        .then((response) => {
          setSaving(false);
          if (callback) callback(response);
          if (response.status === 200) {
            if (type === 'default') {
              if (typeof id === 'undefined') {
                setId(response.body._id);
                history.push({
                  pathname: `/admin/${
                    groupName ? groupName + '/' : ''
                  }edit/${modelName}/${response.body._id}`,
                  state: { prevPath: 'edit' },
                });
              }
              setSaved(true);
              setTimeout(() => setSaved(false), 3000);
              return;
            } else if (type === 'save-and-another') {
              setId(null);
              history.push(`/admin/edit/${modelName}`);
              history.go(0);
              return;
            } else if (type === 'save-and-close') {
              if (modelsWithoutList.includes(modelName)) {
                history.push({
                  pathname: `/admin/${
                    groupName ? groupName + '/' : ''
                  }edit/${modelName}/1`,
                  state: { prevPath: 'edit' },
                });
              } else {
                history.push({
                  pathname: `/admin/${
                    groupName ? groupName + '/' : ''
                  }list/${modelName}/${
                    state && state.page ? `${parseInt(state.page) + 1}` : ''
                  }`,
                  state: { prevPath: 'edit' },
                });
              }
            }
          } else {
            console.log('Error', response.status, response.body.error);
            if (response.status === 403) {
              Cookies.remove('token');
              history.push({ pathname: '/login', state: { prevPath: 'edit' } });
            }
          }
        })
        .catch(() => setSaving(false));
    }
  };

  return (
    <div>
      <Helmet>
        <title>{tabTitle}</title>
      </Helmet>
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-xl wght-semibold">{title}</h2>
        <Link
          to={{
            pathname: `/admin/${
              groupName ? groupName + '/' : ''
            }list/${modelName}/${
              state && state.page ? `${parseInt(state.page) + 1}` : ''
            }`,
            state: { prevPath: 'edit' },
          }}
          className="cursor-pointer hover:wght-semibold hover:text-primary"
        >
          Cancel
        </Link>
      </div>
      {!data && <div className="mb-8">Loading...</div>}
      {data && modelName === name && (
        <>
          <Form
            onChange={updateStored}
            data={data}
            name={name}
            showErrors={showErrors}
            mode={id ? 'edit' : 'create'}
          />
          <div className="sticky bottom-0 z-40 flex justify-between p-4 text-xs bg-white border-t border-grayLight">
            <div>
              {saving ? (
                <span className="text-black wght-bold">Saving...</span>
              ) : (
                <span
                  onClick={() => save()}
                  className="px-2 py-1 cursor-pointer wght-normal hover:wght-semibold hover:text-primary"
                >
                  Save &#8599;{' '}
                </span>
              )}
              {saved && (
                <span className="ml-4 text-black">Saved successfully!</span>
              )}
            </div>
            <div>
              {saving ? null : (
                <span
                  onClick={() => save('save-and-another')}
                  className="px-2 py-1 cursor-pointer wght-normal hover:wght-semibold hover:text-primary"
                >
                  Save &amp; create &#8599;{' '}
                </span>
              )}
            </div>
            <div>
              {saving ? null : (
                <span
                  onClick={() => save('save-and-close')}
                  className="px-2 py-1 cursor-pointer wght-normal hover:wght-semibold hover:text-primary"
                >
                  Save &amp; close &#8599;
                </span>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Edit;
