/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import LanguageSelector from './LanguageSelector'
import MediaViewer from './MediaViewer'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'
import upload from '../Lib/upload'
import { useConfig } from '../Store'

const Image = ({
  storedData,
  onChange,
  name,
  translatable,
  options: { label },
  validations,
  languages,
  files,
  innerForm,
}) => {
  const appConfig = useConfig()
  const [language, setLanguage] = useState(
    translatable ? Object.keys(languages)[0] : null,
  )
  const [value, setValue] = useState(
    defaultValue(name, storedData, translatable),
  )
  const [currentValue, setCurrentValue] = useState()
  const [dragging, setDragging] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [preparing, setPreparing] = useState(false)
  const [showMediaView, setShowMediaViewer] = useState(false)
  const [currentImageName, setCurrentImageName] = useState(false)
  const [imageUrl, setImageUrl] = useState(currentImageName ? `${appConfig.media.public}${appConfig.media.resized}${currentImageName}` : `${process.env.PUBLIC_URL}/assets/images/no-image-small.jpg`)

  const updateValue = (v, url) => {
    if (translatable) {
      value[language] = v
      setValue({ ...value })
    } else {
      setValue(v)
    }
    if (showMediaView) {
      setShowMediaViewer(false)
      setImageUrl(url)
    }
  }

  const remove = e => {
    e.preventDefault()
    e.stopPropagation()
    if (window.confirm('Confirm?')) {
      updateValue('')
      setCurrentImageName(false)
    }
  }

  const onDrop = e => {
    e.stopPropagation()
    e.preventDefault()
    setDragging(false)
    const supportedFilesTypes = ['image/jpeg', 'image/png', 'image/gif']
    const { type } = e.dataTransfer.files[0]
    if (supportedFilesTypes.indexOf(type) > -1) {
      setUploading(true)
      upload(appConfig, e.dataTransfer.files[0], (id, info) => {
        setCurrentImageName(false)
        updateValue(id)
        setPreparing(true)

        let timer = 0
        const imageIsAvailable = setInterval(() => {
          fetch(`${appConfig.media.public}${appConfig.media.resized}${info.resizedName}`).then((response) => {
            if(response.status === 200) {
              clearInterval(imageIsAvailable)
              setCurrentImageName(info.resizedName)
              setPreparing(false)
              setUploading(false)
            } else if (timer >= 15) {
              clearInterval(imageIsAvailable)
              setCurrentImageName(false)
              setPreparing(false)
              setUploading(false)
              alert('Upload request timeout')
            } else {
              timer += 3
            }
          }).catch((e) => {
            clearInterval(imageIsAvailable)
            alert('Upload request error: ', e)
          }) 
        }, 3000)

      }, () => {
        setUploading(false)
      }) 
    }
  }

  useEffect(() => {
    setCurrentValue(translatable ? `${value[language] || ''}` : `${value}`)
  }, [value, language, translatable])

  useEffect(() => {
    if (currentValue && files[currentValue]) {
      setCurrentImageName(translatable ? `${files[currentValue[language]] || ''}` : `${files[currentValue]}`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue])

  useEffect(() => {
    onChange(name, value, Errors(value, validations, translatable, languages))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    setImageUrl(currentImageName ? `${appConfig.media.public}${appConfig.media.resized}${currentImageName}` : `${process.env.PUBLIC_URL}/assets/images/no-image-small.jpg`)
  }, [currentImageName])

  const input = (
    <div>
      <label>{label}</label>
      <div
        className="relative flex items-center bg-white rounded cursor-pointer"
        onClick={() => {
          if (!uploading) {
            setShowMediaViewer(true)
          }
        }}
      >
        <div
          onClick={remove}
          className="absolute top-0 right-0 mt-1 mr-2 text-gray hover:text-black"
        >
          ✕
        </div>
        <div className="relative flex items-center justify-center w-16 h-16 overflow-hidden rounded-tl rounded-bl">
          {!preparing && <img className="rounded-l" alt="Preview" src={imageUrl} />}
          {preparing && <img className="absolute w-6" alt="Preview" src={`${process.env.PUBLIC_URL}/assets/images/spinner.gif`} />}
        </div>
        <div
          onDragEnter={() => setDragging(true)}
          onDragLeave={() => setDragging(false)}
          onDragOver={e => e.preventDefault() && e.stopPropagation()}
          onDrop={e => onDrop(e) && setDragging(false)}
          className={`${
            dragging ? 'border-gray' : ''
          } h-16 border border-white rounded-tr rounded-br flex-1 text-xs text-gray flex justify-center items-center`}
        >
          <span className="text-center pointer-events-none">
            {uploading && !preparing && 'Uploading...'}
            {preparing && 'Preparing preview...'}
            {!uploading && !preparing && <span>
              Drag to upload
              <span className="block text-xxs">Click to select</span>
            </span>}
          </span>
        </div>
      </div>
      {showMediaView && (
        <MediaViewer
          type="image"
          value={currentValue}
          onChange={updateValue}
          onClose={() => setShowMediaViewer(false)}
        />
      )}
    </div>
  )

  return translatable ? (
    <LanguageSelector
      language={language}
      languages={languages}
      onChangeLanguage={v => setLanguage(v)}
    >
      {input}
    </LanguageSelector>
  ) : (
    input
  )
}

export default Image
